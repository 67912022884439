import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BreadCrumbsAbout from "../../containers/BreadcrumbsAbout"
import ChangeYourLife from "../../containers/ChangeYourLife"
import ClientTestimonials from "../../containers/ClientsTestimonials"
import Layout from "../../containers/Layout"
import PageTitle from "../../containers/PageTitle"
import SEO from "../../components/SEO"
import { ContentWrapper } from "../../containers/elegant.style"

const TestimonialsPage = ({ data }) => {
  const {
    clientTestimonial,
    seoDetails,
    title,
    subtile,
    text,
  } = data.contentfulClientTestimonials
  return (
    <>
      <SEO
        title={seoDetails.metaTitle}
        shortDesc={seoDetails.metaShortDescription}
        description={seoDetails.metaDescription}
      />
      <Layout>
        <ContentWrapper>
          <BreadCrumbsAbout currentPage="Client Testimonials" />
          <PageTitle title={title} subtitle={subtile} />
          <ClientTestimonials
            text={text}
            clientTestimonial={clientTestimonial}
          />
          <ChangeYourLife
            whiteBG
            title="Ready to change your life?"
            text="Begin your journey with us today. With Elegant Beauty clinics across the Bournemouth, Poole and Southampton area, we invite you to visit us for a free consultation at your convenience."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default TestimonialsPage

export const clientsTestimonialsQuery = graphql`
  query clientsTestimonials {
    contentfulClientTestimonials {
      title
      subtile
      text {
        text
      }
      seoDetails {
        metaTitle
        metaShortDescription
        metaDescription
      }
      clientTestimonial {
        author
        text {
          text
        }
      }
    }
  }
`
