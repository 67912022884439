import Fade from "react-reveal/Fade"
import React from "react"

import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Quote from "../../common/assets/image/elegant/quotes-testimonials.svg"
import Text from "../../components/Text"
import SectionWrapper, {
  Content,
  DetailsInner,
} from "./clientestimonials.style"

const ClientsTestimonials = ({ clientTestimonial, text }) => {
  return (
    <SectionWrapper>
      <Container width="925px">
        <Fade up delay={80}>
          <Text content={text.text} className="text" />
        </Fade>
      </Container>
      <Container width="865px" className="container_wrapper">
        <Fade up delay={100}>
          {clientTestimonial.map((item, index) => {
            return (
              <DetailsInner>
                <Content>
                  <Fade up delay={400}>
                    <img src={Quote} alt="quote" />
                    <Heading as="h1" content={item.text.text} />
                    <Text content={item.author} />
                  </Fade>
                </Content>
              </DetailsInner>
            )
          })}
        </Fade>
      </Container>
    </SectionWrapper>
  )
}

export default ClientsTestimonials
